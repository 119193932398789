import React, { useCallback, useEffect } from 'react'
import { useDrag } from '@use-gesture/react'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { useSpring, useTransition, animated, config } from '@react-spring/web'
import { useActiveBreakpoint } from 'hooks/useActiveBreakpoint'

const AnimatedDialogOverlay = animated(DialogOverlay)
const AnimatedDialogContent = animated(DialogContent)

interface ModalProps {
	isOpen: boolean
	onDismiss: () => void
	minHeight?: number | false
	maxHeight?: number
	initialFocusRef?: React.RefObject<any>
	children?: React.ReactNode
}

export default function Modal({isOpen, onDismiss, minHeight = false, maxHeight = 500, initialFocusRef, children}: ModalProps) {
	const backgroundTransition = useTransition(isOpen, {
		config: { mass: 1, tension: 80, friction: 18 },
		from: { opacity: 0},
		enter: { opacity: 1},
		leave: { opacity: 0},
	})

	const contentTransition = useTransition(isOpen, {
		config: { mass: 1, tension: 300, friction: 18 },
		from: { opacity: 0, scale: 1.2 },
		enter: { opacity: 1, scale: 1 },
		leave: { opacity: 0, scale: 0.8 },
	})

	// Always 0, means modal distance to bottom
	const height = 0;
	const [{ y }, api] = useSpring(() => ({ y: 1000 }))
	const { md } = useActiveBreakpoint();

	const open = useCallback(({ canceled }) => {
		api.start({ y: 0, immediate: false, config: canceled ? config.wobbly : config.stiff })
	}, [api])

	const close = async () => {
		await api.start({ y: 1000, immediate: false })
		onDismiss();
	}

	useEffect(() => {
		if (isOpen) open({ canceled: false })
	}, [isOpen, open])


	const bind = useDrag(
		({ last, velocity: [, vy], direction: [, dy], movement: [, my], cancel, canceled }) => {
			// if the user drags up passed a threshold, then we cancel
			// the drag so that the sheet resets to its open position
			if (my < -70) cancel()

			// when the user releases the sheet, we check whether it passed
			// the threshold for it to close, or if we reset it to its open positino
			if (last) {
				my > 70 || (vy > 0.5 && dy > 0) ? close() : open({ canceled })
			}
			// when the user keeps dragging, we just move the sheet according to
			// the cursor position
			else api.start({ y: my, immediate: true })
		},
		{ from: () => [0, y.get()], filterTaps: true, bounds: { top: 0 }, rubberband: true }
	)

	return (
		<>
			{backgroundTransition((backgroundStyle, item) => item && (
				contentTransition((contentStyle, item) => item && (
					<AnimatedDialogOverlay className="outline-none bg-black/30" style={backgroundStyle} onDismiss={close} initialFocusRef={initialFocusRef}>
						<AnimatedDialogContent
							{...(!md
								? {
									...bind(),
									style: { bottom: `calc(${height}px)`, y },
								}
								: {})}
							aria-label="dialog content"
							className={`flex max-w-full sm:max-w-[320px] w-full sm:w-8/12 min-h-[${minHeight}vh] max-h-[${maxHeight}vh] m-0 p-0 bg-base-900 text-base-0 relative touch-none ${md ? 'self-center rounded-2xl' : 'self-end rounded-t-2xl'}`}
						>
							{!md ? <div className='absolute w-full -top-4'> <div className=' h-1 w-10 mx-auto bg-purple-700 m-1.5'></div></div> : ''}
							{/* prevents the automatic focusing of inputs on mobile by the reach dialog */}
							{!initialFocusRef && !md ? <div tabIndex={1} /> : null}
							<animated.div className='flex flex-col w-full' style={contentStyle}>
								<div className='relative '>
									{children}
								</div>
							</animated.div>
						</AnimatedDialogContent>
					</AnimatedDialogOverlay>
				))
			))}
		</>
	)
}