import { OrthographicCamera } from "@react-three/drei";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { wrapEffect, EffectComposer } from "@react-three/postprocessing";
import { Effect } from "postprocessing";
import { useRef } from "react";
import * as THREE from "three";
import { v4 as uuidv4 } from "uuid";

/* eslint-disable react/no-unknown-property */
const fragmentShader = `
    precision highp float;

    uniform float colorNum;
    uniform float pixelSize;
    uniform bool blending;


    const float bayerMatrix8x8[64] = float[64](
        0.0/ 64.0, 48.0/ 64.0, 12.0/ 64.0, 60.0/ 64.0,  3.0/ 64.0, 51.0/ 64.0, 15.0/ 64.0, 63.0/ 64.0,
        32.0/ 64.0, 16.0/ 64.0, 44.0/ 64.0, 28.0/ 64.0, 35.0/ 64.0, 19.0/ 64.0, 47.0/ 64.0, 31.0/ 64.0,
        8.0/ 64.0, 56.0/ 64.0,  4.0/ 64.0, 52.0/ 64.0, 11.0/ 64.0, 59.0/ 64.0,  7.0/ 64.0, 55.0/ 64.0,
        40.0/ 64.0, 24.0/ 64.0, 36.0/ 64.0, 20.0/ 64.0, 43.0/ 64.0, 27.0/ 64.0, 39.0/ 64.0, 23.0/ 64.0,
        2.0/ 64.0, 50.0/ 64.0, 14.0/ 64.0, 62.0/ 64.0,  1.0/ 64.0, 49.0/ 64.0, 13.0/ 64.0, 61.0/ 64.0,
        34.0/ 64.0, 18.0/ 64.0, 46.0/ 64.0, 30.0/ 64.0, 33.0/ 64.0, 17.0/ 64.0, 45.0/ 64.0, 29.0/ 64.0,
        10.0/ 64.0, 58.0/ 64.0,  6.0/ 64.0, 54.0/ 64.0,  9.0/ 64.0, 57.0/ 64.0,  5.0/ 64.0, 53.0/ 64.0,
        42.0/ 64.0, 26.0/ 64.0, 38.0/ 64.0, 22.0/ 64.0, 41.0/ 64.0, 25.0/ 64.0, 37.0/ 64.0, 21.0 / 64.0
    );

    vec3 dither(vec2 uv, vec3 color) {
        int x = int(uv.x * resolution.x) % 8;
        int y = int(uv.y * resolution.y) % 8;
        float threshold = bayerMatrix8x8[y * 8 + x];

        color.rgb += threshold * 0.6;
        color.r = floor(color.r * (colorNum - 1.0) + 0.5) / (colorNum - 1.0);
        color.g = floor(color.g * (colorNum - 1.0) + 0.5) / (colorNum - 1.0);
        color.b = floor(color.b * (colorNum - 1.0) + 0.5) / (colorNum - 1.0);

        return color;
    }

    const float MASK_INTENSITY = 2.0;
    const float SPREAD = 0.00025;

    void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor) {
    // XOR shader CRT mask
    vec2 pixel = uv * resolution;
    vec2 coord = pixel / pixelSize;
    vec2 subcoord = coord * vec2(3,1);
    vec2 cellOffset = vec2(0, mod(floor(coord.x), 1.0) * 0.5);

    float ind = mod(floor(subcoord.x), 1.0);
    vec3 maskColor = vec3(ind == 0.0, ind == 1.0, ind == 0.) * 1.0;


    vec2 rgbCellUV = floor(coord+cellOffset) * pixelSize / resolution;

    vec4 color = vec4(1.0);
    color.r = texture2D(inputBuffer, rgbCellUV + SPREAD).r;
    color.g = texture2D(inputBuffer, rgbCellUV).g;
    color.b = texture2D(inputBuffer, rgbCellUV - SPREAD).b;
    
    color.rgb = dither(rgbCellUV, color.rgb);

    if(blending) {
        color.rgb *= 0.06 + (maskColor - 0.99) * MASK_INTENSITY;
    } else {
        color.rgb *= maskColor;
    }


    outputColor = color;
    }
`;


const waveFragmentShader = `
    precision highp float;
    uniform vec2 resolution;
    uniform float time;

    vec4 mod289(vec4 x) {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
    }

    vec4 permute(vec4 x) {
    return mod289(((x*34.0)+1.0)*x);
    }

    vec4 taylorInvSqrt(vec4 r) {
    return 1.79284291400159 - 0.85373472095314 * r;
    }

    vec2 fade(vec2 t) {
    return t*t*t*(t*(t*6.0-15.0)+10.0);
    }

    // Classic Perlin noise
    float cnoise(vec2 P) {
        vec4 Pi = floor(P.xyxy) + vec4(0.0, 0.0, 1.0, 1.0);
        vec4 Pf = fract(P.xyxy) - vec4(0.0, 0.0, 1.0, 1.0);
        Pi = mod289(Pi); // To avoid truncation effects in permutation
        vec4 ix = Pi.xzxz;
        vec4 iy = Pi.yyww;
        vec4 fx = Pf.xzxz;
        vec4 fy = Pf.yyww;

        vec4 i = permute(permute(ix) + iy);

        vec4 gx = fract(i * (1.0 / 41.0)) * 2.0 - 1.0 ;
        vec4 gy = abs(gx) - 0.5 ;
        vec4 tx = floor(gx + 0.5);
        gx = gx - tx;

        vec2 g00 = vec2(gx.x,gy.x);
        vec2 g10 = vec2(gx.y,gy.y);
        vec2 g01 = vec2(gx.z,gy.z);
        vec2 g11 = vec2(gx.w,gy.w);

        vec4 norm = taylorInvSqrt(vec4(dot(g00, g00), dot(g01, g01), dot(g10, g10), dot(g11, g11)));
        g00 *= norm.x;
        g01 *= norm.y;
        g10 *= norm.z;
        g11 *= norm.w;

        float n00 = dot(g00, vec2(fx.x, fy.x));
        float n10 = dot(g10, vec2(fx.y, fy.y));
        float n01 = dot(g01, vec2(fx.z, fy.z));
        float n11 = dot(g11, vec2(fx.w, fy.w));

        vec2 fade_xy = fade(Pf.xy);
        vec2 n_x = mix(vec2(n00, n01), vec2(n10, n11), fade_xy.x);
        float n_xy = mix(n_x.x, n_x.y, fade_xy.y);
        return 2.3 * n_xy;
    }

    const int OCTAVES = 8;

    float fbm(vec2 p) {
        // Initial values
        float value = -0.2;
        float amplitude = 1.0;
        float frequency = 2.5;
        // Loop of octaves
        for (int i = 0; i < OCTAVES; i++) {
            value += amplitude * abs(cnoise(p));
            p *= frequency;
            amplitude *= 0.35;
        }
        return value;
    }

    float pattern(vec2 p) {  
        vec2 p2 = p - time * 0.05;
        vec2 p3 = p + cos(time * 0.1);

        return fbm(p - fbm(p + fbm(p2)));
    }

    void main() {

    vec2 uv = gl_FragCoord.xy/resolution.xy;
    uv -= 0.5;
    uv.x *= resolution.x / resolution.y;

    vec3 col = vec3(0.0);

    float f = pattern(uv);

    col = mix( vec3(0.0), vec3(1.0), f );

    gl_FragColor = vec4(col, 1.0);
    }
`

const waveVertexShader = `
    precision highp float;
    varying vec2 vUv;

    void main() {
    vUv = uv;

    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;

    gl_Position = projectedPosition;
    }
`

const DPR = 1;

class RetroEffectImpl extends Effect {
    constructor() {
        const uniforms = new Map([
            ["colorNum", new THREE.Uniform(16.0)],
            ["pixelSize", new THREE.Uniform(1.0)],
            ["blending", new THREE.Uniform(false)],
        ]);

        super("RetroEffect", fragmentShader, {
            uniforms,
        });

        this.uniforms = uniforms;
    }

    set blending(value) {
        this.uniforms.get("blending").value = value;
    }

    get blending() {
        return this.uniforms.get("blending").value;
    }

    set colorNum(value) {
        this.uniforms.get("colorNum").value = value;
    }

    get colorNum() {
        return this.uniforms.get("colorNum").value;
    }

    set pixelSize(value) {
        this.uniforms.get("pixelSize").value = value;
    }

    get pixelSize() {
        return this.uniforms.get("pixelSize").value;
    }
}

const RetroEffect = wrapEffect(RetroEffectImpl);

const DitheredWaves = () => {
    const mesh = useRef();
    const effect = useRef();
    const { viewport } = useThree();

    const uniforms = {
        time: {
            value: 0.0,
        },
        resolution: new THREE.Uniform(new THREE.Vector2()),
    };

    useFrame((state) => {
        effect.current.colorNum = parseInt(16, 10);
        effect.current.blending = true;
        effect.current.pixelSize = parseInt(1, 10);

        const { clock } = state;
        mesh.current.material.uniforms.time.value = clock.getElapsedTime();
        mesh.current.material.uniforms.resolution.value = new THREE.Vector2(
            window.innerWidth * DPR,
            window.innerHeight * DPR
        );
    })

    return (
        <>
            <OrthographicCamera makeDefault position={[0, 0, 1000]} zoom={120} />

            <mesh ref={mesh} scale={[viewport.width, viewport.height, 1]}>
                <planeGeometry args={[1, 1]} />
                <shaderMaterial
                    key={uuidv4()}
                    fragmentShader={waveFragmentShader}
                    vertexShader={waveVertexShader}
                    uniforms={uniforms}
                    wireframe={false}
                />
            </mesh>
            <EffectComposer>
                <RetroEffect ref={effect} />
            </EffectComposer>
        </>
    );
};

const Scene = () => {
    return (
        <>
            <Canvas shadows dpr={[1, 2]}>
                <DitheredWaves />
            </Canvas>
        </>
    );
};


export default Scene;